// LanguageButton.jsx
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

import getCurrentLanguage, {
  getLanguageTranslationKey,
} from '../resource/getCurrentLanguage.js';
import { ButtonId } from '../resource/mixpanel.js';
import { TranslationNamespace } from '../resource/translationNamespace.js';

import ResetButtonStyle from '../style/ResetButtonStyle.js';
import { color } from '../style/variables.js';

import LanguageTealblueIconSource from '../../img/ic_language_tealblue.svg';
import LanguageWhiteIconSource from '../../img/ic_language_white.svg';
import NextTealblueIconSource from '../../img/ic_next_tealblue.svg';
import NextWhiteIconSource from '../../img/ic_next_white.svg';

export const LayoutType = {
  HAMBURGER: 'hamburger',
};

export const LanguageButton = ({
  modalViewRootId = null,
  layoutType = null,
  isLanguageMenuOpened = false,
  t,
  i18n,
  toggleModal = () => null,
}) => {
  const isHamburger = LayoutType.HAMBURGER === layoutType;
  return (
    <StyledLanguageButton
      isHamburger={isHamburger}
      id={modalViewRootId}
      data-element_id={ButtonId.Setting.ButtonLanguage}
      onClick={event => {
        event.stopPropagation();
        if (isHamburger) {
          toggleModal({
            modalId: 'HamburgerMenuModal',
            isOpen: false,
          });
        }
        return toggleModal({
          modalId: 'LanguageMenu',
          isOpen: !isLanguageMenuOpened,
          payload: {
            modalViewRootId,
          },
        });
      }}
      isMenuOpened={isLanguageMenuOpened}
    >
      <Title isHamburger={isHamburger} isMenuOpened={isLanguageMenuOpened}>
        <LanguageIcon
          isHamburger={isHamburger}
          alt="language icon"
          src={
            isHamburger
              ? LanguageWhiteIconSource.src || LanguageWhiteIconSource
              : isLanguageMenuOpened
                ? LanguageTealblueIconSource.src || LanguageTealblueIconSource
                : LanguageWhiteIconSource.src || LanguageWhiteIconSource
          }
        />
        {isHamburger
          ? t('language', {
              ns: TranslationNamespace.SETTINGS,
            })
          : null}
      </Title>
      <SelectedLanguage
        isHamburger={isHamburger}
        isMenuOpened={isLanguageMenuOpened}
      >
        {t(
          getLanguageTranslationKey({
            language: i18n.language || getCurrentLanguage(),
          }),
          {
            ns: TranslationNamespace.SETTINGS,
          }
        )}
        <NextIcon
          isHamburger={isHamburger}
          width={isHamburger ? 24 : 16}
          height={isHamburger ? 24 : 16}
          alt="switch language"
          src={
            isHamburger
              ? NextWhiteIconSource.src || NextWhiteIconSource
              : isLanguageMenuOpened
                ? NextTealblueIconSource.src || NextTealblueIconSource
                : NextWhiteIconSource.src || NextWhiteIconSource
          }
        />
      </SelectedLanguage>
    </StyledLanguageButton>
  );
};

LanguageButton.propTypes = {
  modalViewRootId: PropTypes.string,
  layoutType: PropTypes.string,
  isLanguageMenuOpened: PropTypes.bool,
  i18n: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  toggleModal: PropTypes.func,
};

const StyledLanguageButton = styled.button.attrs(
  ({ isMenuOpened, isHamburger }) => ({
    style: {
      borderColor: isMenuOpened ? color.tealBlue : color.neutral[100],
      borderWidth: isHamburger ? 'medium' : '1px',
      borderStyle: isHamburger ? 'none' : 'solid',
      color: isMenuOpened ? color.tealBlue : color.neutral[100],
      width: isHamburger ? '100%' : 'width',
    },
  })
)`
  ${ResetButtonStyle};
  position: relative;
  border-radius: 4px;
  padding: 0px 8px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.div.attrs(({ isHamburger, isMenuOpened }) => ({
  style: {
    color: isHamburger
      ? color.neutral[100]
      : isMenuOpened
        ? color.tealBlue
        : color.neutral[100],
  },
}))`
  display: flex;
  align-items: center;
  font-size: 14px;
`;

const LanguageIcon = styled.img.attrs(({ isHamburger }) => ({
  style: {
    marginRight: `${isHamburger ? 8 : 4}px`,
  },
}))`
  width: 16px;
  height: 16px;
  object-fit: contain;
`;

const SelectedLanguage = styled.div.attrs(({ isHamburger, isMenuOpened }) => ({
  style: {
    color: isHamburger
      ? color.neutral[30]
      : isMenuOpened
        ? color.tealBlue
        : color.neutral[100],
  },
}))`
  display: flex;
  align-items: center;
  font-size: 14px;
`;

const NextIcon = styled.img.attrs(({ isHamburger }) => ({
  style: {
    width: `${isHamburger ? 24 : 16}px`,
    height: `${isHamburger ? 24 : 16}px`,
  },
}))`
  margin-left: 4px;
  object-fit: contain;
`;

export default withTranslation()(LanguageButton);
